.MessageDialog {
  @apply flex flex-col items-center justify-stretch gap-y-6 select-none;
  padding: var(--dop-space-xl, 24px);

  &.small {
    width: 320px;
  }

  &.medium {
    width: 400px;
  }

  &.large {
    width: 460px;
  }
}

.MessageDialog__Title {
  @apply absolute top-2 left-2 text-lg font-semibold;
}

.MessageDialog__Body {
  font-size: var(--dop-font-size-base, 16px);
  padding: var(--dop-space-md, 12px) 0;
}

.MessageDialog__Footer {
  @apply w-full flex justify-end gap-x-4;
}
