.Dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
}

.DialogTitle {
  @apply text-lg;
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  font-weight: 600;
}

.DialogDescription {
  @apply text-2xs;
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  font-style: normal;
  font-weight: 400;
}
