.DownloadReasonDialog {
  @apply flex flex-col p-[--dop-space-xl] w-[460px] gap-[16px];
}

.DownloadReasonDialog__Title {
  @apply text-[#1C1C1C] text-xl font-[600];
}

.DownloadReasonDialog__Description {
  @apply text-sm text-[#1c1c1c] font-[400];
}

.DownloadReasonDialog__Input {
  @apply w-full h-[40px] 
}

.DownloadReasonDialog__ActionButtonGroup {
  @apply flex justify-end items-center gap-[12px] mt-[8px];
}
