.Wrapper {
  @apply flex flex-col justify-center items-center h-[100vh] w-[100vw];
}

.Card {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}

.Logo {
  @apply grid justify-center gap-[8px];
}

.ErrorBox {
  @apply flex items-center bg-[#FFDD88] py-[8px] px-[12px] rounded-lg mt-[50px];
  transition: all ease-in-out 1s;
}
