.Box {
  padding: 40px;
  border-radius: 8px;
  border: 1px solid #e6e7ea;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 40px;
}

.Header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
}

.Title {
  color: var(--Gray-800, #333);
  font-size: 22px;
  font-style: normal;
  font-family: 'Noto Sans KR';
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.32px;
}

.Description {
  color: var(--Gray-400, #aaa);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}
