.RadioOption {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
}

.RadioInput {
  flex-shrink: 0;
  width: 16px;
  height: 16px;

  border-radius: var(--Radius-L, 16px);
  border: 1px solid var(--control-border-basic, #cfd1d4);
  background: var(--control-bg-basic, #fff);
  accent-color: var(--control-border-basic-active, #38393a);

  &:hover {
    border: 1px solid var(--control-border-basic-hover, #9b9c9e);
  }

  &:checked {
    border: 1px solid var(--control-border-basic-active, #38393a);
  }

  &:disabled {
    border: 1px solid var(--control-border-basic-disabled, #e1e3e6);
    background: var(--control-bg-basic-disabled, #eaecef);
  }
}

.RadioLabel {
  display: flex;
  align-items: center;

  color: var(--text-basic-level1, #1c1c1c);

  /* Body/Medium-R */
  font-family: var(--font-Family, Pretendard);
  font-size: var(--font-size-Small, 14px);
  font-style: normal;
  font-weight: 400;
  letter-spacing: var(--font-letter-spacing-Small, -0.28px);
}

.RadioLabel.disabled {
  color: var(--text-basic-disabled, #c5c7ca);
}
