.Container {
  padding: 40px 72px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
.HeaderTitle {
  @apply text-xl;
  color: var(--Gray-800, #333);
  font-weight: 600;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;
}

.Description {
  color: #888;
  @apply text-sm;
}

.Contents {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 12px 0;
}

.Field {
  display: flex;
}

.FieldTitle {
  @apply text-sm;
  font-weight: 500;
  min-width: 200px;
  color: var(--dop-color-text-level1, #363636);
}

.FieldItemRow {
  width: 100%;
  display: flex;
}

.Select {
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 0px 4px;
  border-radius: 8px;
}
