.RecentCollectedDate {
  @apply text-2xs;
  color: var(--dop-color-text-basic-level3, #999);
  padding-bottom: 8px;
}

.TableRoot {
  overflow-x: auto;
}

.TableContent {
  table-layout: fixed;
  width: 100%;
}

/* .TableContent th,
.TableContent td {
  text-align: left !important;
  max-width: 103px !important;
} */

.TableContent th:nth-child(1),
.TableContent td:nth-child(1),
.TableContent th:nth-child(1) p,
.TableContent td:nth-child(1) p {
  text-align: left;
  box-sizing: content-box;
  width: 88px !important;
  max-width: 88px !important;
  min-width: 88px !important;
}

.TableContent th:nth-child(2),
.TableContent td:nth-child(2) {
  text-align: left;
  box-sizing: content-box;
  width: 103px !important;
  max-width: 103px !important;
  min-width: 103px !important;
}

.TableContent td:nth-child(3),
.TableContent td:nth-child(4) {
  width: auto;
  max-width: 100%;
  text-align: center !important;
}

.Date {
  font-size: 13px;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
}

.Item {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 600;
  text-align: left;
}

.Count {
  text-align: center;
}

.HilightCount {
  color: var(--dop-color-text-status-information, #5296ff);
  font-weight: 700;
}
