.SearchDialog {
  width: 1000px;
  height: calc(100vh - 120px);

  .container {
    @apply w-full h-full;
    row-gap: var(--dop-space-md, 12px);
    padding: 40px 44px;
    overflow: auto;

    .header {
      @apply flex flex-col;
      row-gap: var(--dop-space-md, 12px);
      margin-bottom: var(--dop-space-2xl, 32px);
    }
  }
}

.CategoryContainer {
  @apply flex items-center;

  column-gap: var(--dop-space-sm, 8px);
}

.CateScrollButtonContainer {
  position: relative;

  flex-grow: 1;
  overflow: hidden;
}

.CateTabContainer {
  @apply flex items-center flex-nowrap;

  overflow: hidden;

  column-gap: var(--dop-space-sm, 8px);
}

.ScrollButton {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px !important;
  height: 28px !important;

  padding: 7px;

  background-color: var(--dop-color-bg-basic, #ffffff);

  border-radius: 50%;
  border: 1px solid var(--dop-color-border-basic-level3, #cfd1d4);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

  transition: opacity 0.15s ease-in-out;
}

.ScrollButton.disabled {
  opacity: 0;
}

.ScrollButton.active {
  opacity: 1;
}

.ScrollButton.left {
  left: 0px;
  top: 4px;
}

.ScrollButton.right {
  right: 0px;
  top: 4px;
}

.CateTab {
  @apply shrink-0 flex items-center justify-center rounded-full;
  height: 36px;
  padding: 0 16px;
  border: 1px solid var(--dop-color-border-basic-level2, #cfd1d4);

  &:hover {
    @apply cursor-pointer;
  }

  &.active {
    color: var(--dop-color-text-basic-white, #ffffff);
    background-color: var(--dop-color-bg-basic-active, #38393a);
    border: 1px solid var(--dop-color-bg-basic-active, #38393a);
  }
}

.SearchResultContainer {
  @apply flex flex-col items-stretch gap-y-6 divide-y;
}

.SearchResultView {
  @apply flex flex-col items-stretch gap-y-3;

  .heading {
    @apply flex items-center text-lg font-semibold gap-x-2;
    height: 40px;
  }

  .footer {
    @apply flex items-center justify-center;

    .moreBtn {
      height: 28px;
      .label {
        @apply text-xs;
      }
    }
  }
}

.SearchResultSummary {
  &:not(:first-child) {
    padding-top: var(--dop-space-xl, 24px);
  }
}
