.ContentAnimation {
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.OverlayAnimation {
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translateX(-100px);
    transform: translate(-50%, -48%) scale(0.76);
    transition: ease-out;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    transform: translate(-50%, -50%) scale(1);
    transition: ease-out;
  }
}
