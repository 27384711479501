/** Root **/
.Root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.Root.small {
  gap: var(--Radius-M, 4px);
}

.Root.medium {
  gap: var(--Radius-S, 8px);
}

.Root.large {
  gap: var(--Radius-M, 12px);
}

/** Label **/
.Label {
  @apply text-sm;
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  transition: all 0.25s ease-in-out;
}

.Label.default {
  font-weight: 500;
}

.Label.legendM {
  position: absolute;
  top: 11.5px;
  left: 12px;
  font-weight: 400;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

.Label.legendL {
  position: absolute;
  top: 17.5px;
  left: 12px;
  font-weight: 400;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

.Label.activeM {
  line-height: var(--font-multi-line-height-2XSmall, 18px);
  font-size: 12px;
  top: 4px;
}

.Label.activeL {
  line-height: var(--font-multi-line-height-2XSmall, 18px);
  font-size: 12px;
  top: 8px;
}

/** InputContainer **/
.InputContainer {
  display: flex;
  align-items: center;
  border-radius: var(--dop-space-sm, 8px);
  border: 1px solid var(--dop-color-field-border-basic, #cfd1d4);
  &.rect {
    border-radius: var(--dop-space-sm, 8px);
    padding: 0px var(--dop-space-md, 12px);
  }
  &.roundedFull {
    @apply rounded-full;
    padding: 0px var(--dop-space-md, 12px) 0px var(--dop-space-xl, 24px);
  }

  &.roundedFull.medium {
    padding: 0px var(--dop-space-md, 12px) 0px 20px;
  }
  &.roundedFull.large {
    padding: 0px var(--dop-space-md, 12px) 0px var(--dop-space-xl, 24px);
  }

  &:hover {
    border: 1px solid var(--dop-color-field-border-basic-hover, #9b9c9e);
  }
  transition: all 0.25s ease-in-out;

  &.active {
    border: 1px solid var(--dop-color-field-border-basic-active, #38393a);
  }

  &.error {
    border: 1px solid var(--dop-color-field-border-basic-error, #ee2e2e);
  }
  &.small {
    height: 32px;
  }

  &.medium {
    height: 40px;
  }

  &.large {
    height: 56px;
  }

  &.disabled {
    border-radius: var(--dop-space-sm, 8px);
    border: 1px solid var(--dop-color-field-border-basic-disabled, #e1e3e6);
    background: var(--dop-color-field-bg-disabled, #eaecef);
  }
}

/** Meta, Header **/
.Meta {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
}

.Header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
}

/** ErrorMessage **/
.ErrorMessage {
  @apply text-xs;
  color: var(--dop-color-text-status-negative, #ee2e2e);
}

/** Description **/
.Description {
  @apply text-xs;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

/** Input **/
.Input {
  @apply text-sm;
  background-color: transparent;
  flex-grow: 1;
  width: 100%;
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  transition: all 0.25s ease-in-out;
}

.Input.activeS {
  padding-top: 16px;
}

.Input.activeM {
  padding-top: 16px;
}

.Input.activeL {
  padding-top: 24px;
}

/** NumberInput **/
/** PasswordShowButton **/
.PasswordShowButton {
  color: var(--dop-color-icon-basic-level3, #9b9c9e);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}

/** ClearButton **/
.ClearButton {
  color: var(--dop-color-icon-basic-level3, #9b9c9e);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}
