.SearchFilter {
  @apply flex flex-col gap-y-2;
  margin-bottom: 16px;
}

.SearchFilter__Header {
  @apply flex justify-between items-center gap-x-2;

  .heading {
    @apply flex items-center gap-x-3 font-semibold;

    .title {
      @apply flex items-center gap-x-2;
    }
    .large {
      @apply text-2xl;
    }

    .medium {
      @apply text-lg;
    }
  }

  .button {
    padding: 4px;
    border-radius: 8px;
    color: var(--dop-color-icon-basic-level3, #9b9c9e);

    .count {
      @apply text-sm font-semibold;
      margin: 0 4px;
    }
  }

  .button[data-open] {
    color: var(--dop-color-icon-basic-white, #ffffff);
    background-color: var(--dop-bg-basic-black, #000000);
  }

  .button[data-has-filter] {
    color: var(--dop-color-text-status-information, #0d99ff);
    border: 1px solid var(--dop-color-border-status-information, #0d99ff);
    background-color: var(--dop-color-bg-status-information-level2, #e7f5ff);
  }
}

.SearchFilter__Container {
  @apply flex flex-col gap-y-2;
  padding: 32px 24px;
  border-radius: 12px;
  border: 1px solid var(--dop-color-border-basic-level1, #eaecef);
}

.SearchFilter__Footer {
  @apply flex justify-end gap-x-2 mt-4;
}

.SearchFilter__Field {
  @apply flex items-center gap-x-4;
  min-height: 40px;

  :global(.label) {
    @apply shrink-0 self-start;
    padding-top: 10px;
    width: 94px;
    height: 100%;
  }

  :global(.field) {
    @apply grow;
    /* padding: 0 var(--dop-space-sm, 8px);
    column-gap: 16px; */
  }
}
