.SearchIndicator {
  @apply flex items-center;
  width: 300px;
  height: 40px;
  border: 1px solid var(--dop-color-border-field-active, #808080);
  padding: 0 8px 0 16px;
  border-radius: 8px;

  .chip {
    @apply flex items-center rounded-full gap-1 text-sm;
    color: var(--dop-color-text-status-information, #0d99ff);
    background-color: var(--dop-color-bg-status-information-level2, #e7f5ff);
    height: 28px;
    padding: 0 12px;

    button {
      background-color: transparent;
      color: inherit;
    }

    .keyword {
      @apply truncate;
      max-width: 196px;
    }
  }
}

.SearchIcon {
  @apply mr-2;
  color: var(--dop-color-bg-icon-level2, #aaaaaa);
}
