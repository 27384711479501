.TableHeader {
  display: flex;
  justify-content: start;
  height: 48px;
  padding: 0px 8px;
}

.TableCell {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;
}

.TableCell > p {
  display: flex;
  align-items: center;
}

.EmptyMessage {
  display: flex;
  width: 100%;
  padding: 60px 0;
  align-items: center;
  justify-content: center;
}
