@container (min-width: 432px) {
  .Tab {
    width: 112px;
    max-width: 112px;
  }
}

@container (max-width: 431px) {
  .Tab {
    width: 70px;
    max-width: 70px;
  }
}

.Wrapper {
  container-type: inline-size;
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.TabList {
  margin-left: 36px;
  overflow: hidden;
  display: flex;
}

.LeftIcon {
  position: absolute;
  top: 0;
  left: 0;
  color: #999;
  cursor: pointer;
  border-radius: 100%;
}
.RightIcon {
  color: #999;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 100%;
}

.Tab {
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #5b5b5b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.28px;
  cursor: pointer;
}

.CurrentTab {
  border-bottom: 2px solid #363636;
  color: #363636;
  font-weight: 600;
}

.TabTitle {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
