.Table table tbody tr:hover {
  background-color: #f6f7f9;
  border-radius: 10px;
}

.Table table tbody tr:hover td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Table table tbody tr:hover td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Table table tr th:first-child,
.Table table tr td:first-child {
  padding-left: 16px;
}

.Table table tr th:last-child,
.Table table tr td:last-child {
  padding-right: 16px;
}
