@container (max-width: 296px) {
  .TableContent th:nth-child(1),
  .TableContent td:nth-child(1),
  .TableContent th:nth-child(1) p,
  .TableContent td:nth-child(1) p,
  .TableContent th:nth-child(3),
  .TableContent td:nth-child(3),
  .TableContent th:nth-child(3) p,
  .TableContent td:nth-child(3) p {
    box-sizing: content-box;
    max-width: 44px !important;
    min-width: 44px !important;
  }
}

@container (min-width: 297px) {
  .TableContent th:nth-child(1),
  .TableContent td:nth-child(1),
  .TableContent th:nth-child(1) p,
  .TableContent td:nth-child(1) p,
  .TableContent th:nth-child(3),
  .TableContent td:nth-child(3),
  .TableContent th:nth-child(3) p,
  .TableContent td:nth-child(3) p {
    box-sizing: content-box;
    max-width: 53px !important;
    min-width: 53px !important;
  }
}

@container (min-width: 432px) {
  .TableContent th:nth-child(1),
  .TableContent td:nth-child(1),
  .TableContent th:nth-child(1) p,
  .TableContent td:nth-child(1) p,
  .TableContent th:nth-child(3),
  .TableContent td:nth-child(3),
  .TableContent th:nth-child(3) p,
  .TableContent td:nth-child(3) p {
    box-sizing: content-box;
    max-width: 156px !important;
    min-width: 156px !important;
  }
}

.Today {
  display: flex;
  height: 22px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: var(--Space-3XSmall, 2px);
  border-radius: var(--Radius-S, 8px);
  border: 1px solid #0d99ff;
  margin-left: 4px;
  color: #0d99ff;
  font-size: 12px;
}

.RecentCollectedDate {
  @apply text-2xs;
  color: var(--dop-color-text-basic-level3, #999);
  padding-bottom: 8px;
}

.TableRoot {
  overflow-x: auto;
  container-type: inline-size;
  contain: layout;
}

.TableContent {
  table-layout: fixed;
  width: 100%;
}

/* .TableContent th,
.TableContent td {
  text-align: left !important;
  max-width: 103px !important;
} */

.TableContent tbody > tr {
  cursor: pointer;
}

.TableContent th:nth-child(1),
.TableContent td:nth-child(1) {
  text-align: left;
  box-sizing: content-box;
}

.TableContent th:nth-child(2),
.TableContent td:nth-child(2) {
  box-sizing: content-box;
  width: 103px !important;
  max-width: fit-content !important;
  min-width: fit-content !important;
}

.TableContent td:nth-child(3) {
  text-align: left;
}

.TableContent td:nth-child(4) {
  width: auto;
  max-width: 100%;
  text-align: center !important;
}

.TableContent th:nth-child(4),
.TableContent td:nth-child(4),
.TableContent th:nth-child(4) p,
.TableContent td:nth-child(4) p {
  box-sizing: content-box;
  max-width: 32px !important;
  min-width: 32px !important;
}

.TableContent td:nth-child(5) {
  text-align: right !important;
}

.TableContent th:nth-child(5),
.TableContent td:nth-child(5),
.TableContent th:nth-child(5) p,
.TableContent td:nth-child(5) p {
  box-sizing: content-box;
  max-width: 128px !important;
  min-width: 128px !important;
}

.Date {
  font-size: 13px;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
}

.Name {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 600;
  text-align: left;
}

.BankName {
  display: flex;
  align-items: center;
  gap: 4px;
}

.Balance {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 700;
}
