.TableRoot {
  overflow-x: auto;
}

.TableContent {
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
}

.TableContent td {
  text-align: left !important;
}

.TableContent th:nth-child(1),
.TableContent th:nth-child(2),
.TableContent td:nth-child(1),
.TableContent td:nth-child(2) {
  box-sizing: content-box;
  width: 88px !important;
  max-width: 88px !important;
  min-width: 88px !important;
}

.TableContent td:nth-child(3),
.TableContent td:nth-child(4) {
  width: auto;
  max-width: 100%;
}

.DraftDate {
  display: flex;
  align-items: center;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
  font-size: 13px;
}

.FormName {
  max-width: 88px;
  color: var(--dop-color-text-basic-level1, #363636);
  text-align: left;
}

.Title {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 600;
  text-align: left;
}

.PROGRESS {
  @apply text-2xs;
  display: inline-flex;
  padding: var(--Space-XSmall, 6px);
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #49c689;
  background: #e8f8f0;
  color: #49c689;
  font-weight: 500;
}

.COMPLETE {
  @apply text-2xs;
  display: inline-flex;
  padding: var(--Space-XSmall, 6px);
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 4px;
  background: #ccc;
  color: var(--dop-color-text-basic-white, #fff);
  font-weight: 500;
}
