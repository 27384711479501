.DatePicker {
  @apply flex items-center gap-1 p-1 rounded-md;
  border: 1px solid var(--dop-color-field-border-basic, #e1e3e6);
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  &.small {
    @apply !text-2xs;
    width: 96px;
    height: 24px;
    border-radius: 4px;
  }

  &.medium {
    @apply !text-xs;
    width: 109px;
    height: 32px;
    border-radius: 6px;
  }

  &.large {
    @apply !text-sm;
    width: 119px;
    height: 40px;
    border-radius: 8px;
  }

  &.active {
    border: 1px solid var(--dop-color-field-border-basic-active, #38393a);
  }

  &.error {
    border: 1px solid var(--dop-color-field-border-basic-error, #ee2e2e);
  }

  &:disabled,
  &[disabled],
  &:global(.disabled) {
    cursor: not-allowed;
    color: var(--dop-color-text-basic-disabled, #c5c7ca);
    border: 1px solid var(--dop-color-field-border-disabled, #e1e3e6);
    background-color: var(--dop-color-field-bg-disabled, #eaecef);
  }
}
