.dialogAnimation_ContentAnimation__LiYIr {
  animation: dialogAnimation_contentShow__yOb81 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialogAnimation_OverlayAnimation__L42WE {
  animation: dialogAnimation_overlayShow__QYboC 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes dialogAnimation_overlayShow__QYboC {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes dialogAnimation_contentShow__yOb81 {
  from {
    opacity: 0;
    transform: translateX(-100px);
    transform: translate(-50%, -48%) scale(0.76);
    transition: ease-out;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    transform: translate(-50%, -50%) scale(1);
    transition: ease-out;
  }
}

.styles_Box__pKsHz {
  padding: 40px;
  border-radius: 8px;
  border: 1px solid #e6e7ea;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 40px;
}

.styles_Header__Sb2A1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
}

.styles_Title__uTL4S {
  color: var(--Gray-800, #333);
  font-size: 22px;
  font-style: normal;
  font-family: 'Noto Sans KR';
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.32px;
}

.styles_Description__8Z7_0 {
  color: var(--Gray-400, #aaa);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.style_MessageDialog__usqfn {
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  row-gap: 1.5rem;
  padding: var(--dop-space-xl, 24px);
}

  .style_MessageDialog__usqfn.style_small__XHzpp {
    width: 320px;
  }

  .style_MessageDialog__usqfn.style_medium__gEH6G {
    width: 400px;
  }

  .style_MessageDialog__usqfn.style_large__iHhdz {
    width: 460px;
  }

.style_MessageDialog__Title__N_vqm {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: var(--dop-font-size-lg);
  line-height: var(--dop-font-muiti-line-height-lg);
  letter-spacing: var(--dop-font-letter-spacing-lg);
  font-weight: 600;
}

.style_MessageDialog__Body__SbHoV {
  font-size: var(--dop-font-size-base, 16px);
  padding: var(--dop-space-md, 12px) 0;
}

.style_MessageDialog__Footer__9m9ZY {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

/** Root **/
.styles_Root__foMVx {
  display: flex;
  flex-direction: column;
  position: relative;
}

.styles_Root__foMVx.styles_small__8hb8e {
  gap: var(--Radius-M, 4px);
}

.styles_Root__foMVx.styles_medium__q8qHW {
  gap: var(--Radius-S, 8px);
}

.styles_Root__foMVx.styles_large__sfCdC {
  gap: var(--Radius-M, 12px);
}

/** Label **/
.styles_Label__bNyS6 {
  font-size: var(--dop-font-size-sm);
  line-height: var(--dop-font-muiti-line-height-sm);
  letter-spacing: var(--dop-font-letter-spacing-sm);
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  transition: all 0.25s ease-in-out;
}

.styles_Label__bNyS6.styles_default__DbIdG {
  font-weight: 500;
}

.styles_Label__bNyS6.styles_legendM__vT3Gl {
  position: absolute;
  top: 11.5px;
  left: 12px;
  font-weight: 400;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

.styles_Label__bNyS6.styles_legendL__0IRZC {
  position: absolute;
  top: 17.5px;
  left: 12px;
  font-weight: 400;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

.styles_Label__bNyS6.styles_activeM__zYvBP {
  line-height: var(--font-multi-line-height-2XSmall, 18px);
  font-size: 12px;
  top: 4px;
}

.styles_Label__bNyS6.styles_activeL__y93AX {
  line-height: var(--font-multi-line-height-2XSmall, 18px);
  font-size: 12px;
  top: 8px;
}

/** InputContainer **/
.styles_InputContainer__nwbwT {
  display: flex;
  align-items: center;
  border-radius: var(--dop-space-sm, 8px);
  border: 1px solid var(--dop-color-field-border-basic, #cfd1d4);
}
.styles_InputContainer__nwbwT.styles_rect___8oBx {
    border-radius: var(--dop-space-sm, 8px);
    padding: 0px var(--dop-space-md, 12px);
  }
.styles_InputContainer__nwbwT.styles_roundedFull__kjw5L {
  border-radius: 9999px;
    padding: 0px var(--dop-space-md, 12px) 0px var(--dop-space-xl, 24px);
}
.styles_InputContainer__nwbwT.styles_roundedFull__kjw5L.styles_medium__q8qHW {
    padding: 0px var(--dop-space-md, 12px) 0px 20px;
  }
.styles_InputContainer__nwbwT.styles_roundedFull__kjw5L.styles_large__sfCdC {
    padding: 0px var(--dop-space-md, 12px) 0px var(--dop-space-xl, 24px);
  }
.styles_InputContainer__nwbwT:hover {
    border: 1px solid var(--dop-color-field-border-basic-hover, #9b9c9e);
  }
.styles_InputContainer__nwbwT {
  transition: all 0.25s ease-in-out;
}
.styles_InputContainer__nwbwT.styles_active__Zmr1e {
    border: 1px solid var(--dop-color-field-border-basic-active, #38393a);
  }
.styles_InputContainer__nwbwT.styles_error__gvwrB {
    border: 1px solid var(--dop-color-field-border-basic-error, #ee2e2e);
  }
.styles_InputContainer__nwbwT.styles_small__8hb8e {
    height: 32px;
  }
.styles_InputContainer__nwbwT.styles_medium__q8qHW {
    height: 40px;
  }
.styles_InputContainer__nwbwT.styles_large__sfCdC {
    height: 56px;
  }
.styles_InputContainer__nwbwT.styles_disabled__XJlqd {
    border-radius: var(--dop-space-sm, 8px);
    border: 1px solid var(--dop-color-field-border-basic-disabled, #e1e3e6);
    background: var(--dop-color-field-bg-disabled, #eaecef);
  }

/** Meta, Header **/
.styles_Meta__pFs9_ {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
}

.styles_Header__zVt0q {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
}

/** ErrorMessage **/
.styles_ErrorMessage__BNv_3 {
  font-size: var(--dop-font-size-xs);
  line-height: var(--dop-font-muiti-line-height-xs);
  letter-spacing: var(--dop-font-letter-spacing-xs);
  color: var(--dop-color-text-status-negative, #ee2e2e);
}

/** Description **/
.styles_Description__VTa_D {
  font-size: var(--dop-font-size-xs);
  line-height: var(--dop-font-muiti-line-height-xs);
  letter-spacing: var(--dop-font-letter-spacing-xs);
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}

/** Input **/
.styles_Input__MMZtR {
  font-size: var(--dop-font-size-sm);
  line-height: var(--dop-font-muiti-line-height-sm);
  letter-spacing: var(--dop-font-letter-spacing-sm);
  background-color: transparent;
  flex-grow: 1;
  width: 100%;
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  transition: all 0.25s ease-in-out;
}

.styles_Input__MMZtR.styles_activeS__EmrS3 {
  padding-top: 16px;
}

.styles_Input__MMZtR.styles_activeM__zYvBP {
  padding-top: 16px;
}

.styles_Input__MMZtR.styles_activeL__y93AX {
  padding-top: 24px;
}

/** NumberInput **/
/** PasswordShowButton **/
.styles_PasswordShowButton__CN3MA {
  color: var(--dop-color-icon-basic-level3, #9b9c9e);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}

/** ClearButton **/
.styles_ClearButton__42Jt7 {
  color: var(--dop-color-icon-basic-level3, #9b9c9e);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}

.styles_Dialog__BEwj9 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
}

.styles_DialogTitle__saVbj {
  font-size: var(--dop-font-size-lg);
  line-height: var(--dop-font-muiti-line-height-lg);
  letter-spacing: var(--dop-font-letter-spacing-lg);
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  font-weight: 600;
}

.styles_DialogDescription__43n_r {
  font-size: var(--dop-font-size-2xs);
  line-height: var(--dop-font-muiti-line-height-2xs);
  letter-spacing: var(--dop-font-letter-spacing-2xs);
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  font-style: normal;
  font-weight: 400;
}

.style_DownloadReasonDialog__rrb8U {

    display: flex;

    width: 460px;

    flex-direction: column;

    gap: 16px;

    padding: var(--dop-space-xl)
}

.style_DownloadReasonDialog__Title__I70pk {

    font-size: var(--dop-font-size-xl);

    line-height: var(--dop-font-muiti-line-height-xl);

    letter-spacing: var(--dop-font-letter-spacing-xl);

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(28 28 28 / var(--tw-text-opacity))
}

.style_DownloadReasonDialog__Description__CQYtu {

    font-size: var(--dop-font-size-sm);

    line-height: var(--dop-font-muiti-line-height-sm);

    letter-spacing: var(--dop-font-letter-spacing-sm);

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(28 28 28 / var(--tw-text-opacity))
}

.style_DownloadReasonDialog__Input__jzSyR {

    height: 40px;

    width: 100%
}

.style_DownloadReasonDialog__ActionButtonGroup__qyP1S {

    margin-top: 8px;

    display: flex;

    align-items: center;

    justify-content: flex-end;

    gap: 12px
}

.style_root___QJej {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

  .style_root___QJej .style_after__byCfg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='88px' height='88px' viewBox='0 0 88 88' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cpath d='M44%2c0.5 C59.8650505%2c0.5 70.7664452%2c3.40244096 77.6820021%2c10.3179979 C84.597559%2c17.2335548 87.5%2c28.1349495 87.5%2c44 C87.5%2c59.8650505 84.597559%2c70.7664452 77.6820021%2c77.6820021 C70.7664452%2c84.597559 59.8650505%2c87.5 44%2c87.5 C28.1349495%2c87.5 17.2335548%2c84.597559 10.3179979%2c77.6820021 C3.40244096%2c70.7664452 0.5%2c59.8650505 0.5%2c44 C0.5%2c28.1349495 3.40244096%2c17.2335548 10.3179979%2c10.3179979 C17.2335548%2c3.40244096 28.1349495%2c0.5 44%2c0.5 Z' fill='none' stroke-linejoin='round' stroke='rgba(0,0,0,0.08)'%3e%3c/path%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .style_root___QJej .style_image__TvkEC {
		position: relative;
    width: 100%;
    height: 100%;
  }

  .style_root___QJej .style_fallback__PLIZi {
    fill: #ffffff;
  }

  .style_root___QJej .style_background__2RCJJ {
    width: 100%;
    height: 100%;
    /* fill: currentColor; */
  }

@container (min-width: 432px) {
  .styles_Tab__rSRca {
    width: 112px;
    max-width: 112px;
  }
}

@container (max-width: 431px) {
  .styles_Tab__rSRca {
    width: 70px;
    max-width: 70px;
  }
}

.styles_Wrapper__pdWhX {
  container-type: inline-size;
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.styles_TabList__hp6UR {
  margin-left: 36px;
  overflow: hidden;
  display: flex;
}

.styles_LeftIcon__88J6k {
  position: absolute;
  top: 0;
  left: 0;
  color: #999;
  cursor: pointer;
  border-radius: 100%;
}
.styles_RightIcon__w07xN {
  color: #999;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 100%;
}

.styles_Tab__rSRca {
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #5b5b5b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.28px;
  cursor: pointer;
}

.styles_CurrentTab__5RK80 {
  border-bottom: 2px solid #363636;
  color: #363636;
  font-weight: 600;
}

.styles_TabTitle__eDz1F {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.styles_GadgetContentLoader__1TzCs {
  width: 100%;
  height: 325px;
  padding: 30px 40px;
  border: 1px solid #dee2e9;
  border-radius: 12px;
  margin-bottom: 16px;
}

  .styles_GadgetContentLoader__1TzCs .styles_title__NbnSN {
  border-radius: 0.25rem;
    width: 120px;
    height: 32px;
    animation: styles_skeleton-loading__slAW3 1s linear infinite alternate;
}

  .styles_GadgetContentLoader__1TzCs .styles_body__xXbaT {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
    padding: 2rem 0;
}

  .styles_GadgetContentLoader__1TzCs .styles_body__xXbaT .styles_paragraph__gm1b6 {
  border-radius: 0.25rem;
      height: 10px;
      animation: styles_skeleton-loading__slAW3 1s linear infinite alternate;
}

  .styles_GadgetContentLoader__1TzCs .styles_body__xXbaT .styles_pNormal__YWdJC {
      width: 100%;
    }

  .styles_GadgetContentLoader__1TzCs .styles_body__xXbaT .styles_pEnd__IpaQa {
      width: 80%;
    }

@keyframes styles_skeleton-loading__slAW3 {
  0% {
    background-color: hsl(200, 20%, 95%);
  }
  100% {
    background-color: hsl(200, 20%, 90%);
  }
}

.dialog_DialogTrigger__hY7_f {
  display: flex;
  flex-direction: column;
  padding: var(--Space-XLarge, 24px);
  border-radius: var(--Radius-M, 12px);
  border: 1px solid var(--color-border-level4, #f2f2f2);
  gap: var(--Space-Large, 16px);
  flex: 1 1 0%;
}

  .dialog_DialogTrigger__hY7_f:hover {
    border: var(--Space-3XSmall, 1px) solid var(--color-border-level1, #363636);
  }

  .dialog_DialogTrigger__hY7_f:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

.dialog_DialogContent__eMlR2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 640px;
  max-height: 85vh;
  padding: 24px;
}

.dialog_DialogOverlay__Du3qL {
  background-color: black;
  position: fixed;
  inset: 0;
  opacity: 0.5;
  animation: dialog_overlayShow__m_oiq 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.card_Wrapper__pnHah {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_Card__pYrNN {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}

.card_Logo__ywoj7 {
  display: grid;
  justify-content: center;
  gap: 8px;
}

.card_ErrorBox__JGjVZ {
  margin-top: 50px;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 221 136 / var(--tw-bg-opacity));
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all ease-in-out 1s;
}

.styles_SearchIndicator__wY1MT {
  display: flex;
  align-items: center;
  width: 300px;
  height: 40px;
  border: 1px solid var(--dop-color-border-field-active, #808080);
  padding: 0 8px 0 16px;
  border-radius: 8px;
}

  .styles_SearchIndicator__wY1MT .styles_chip__tScnF {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 9999px;
  font-size: var(--dop-font-size-sm);
  line-height: var(--dop-font-muiti-line-height-sm);
  letter-spacing: var(--dop-font-letter-spacing-sm);
    color: var(--dop-color-text-status-information, #0d99ff);
    background-color: var(--dop-color-bg-status-information-level2, #e7f5ff);
    height: 28px;
    padding: 0 12px;
}

  .styles_SearchIndicator__wY1MT .styles_chip__tScnF button {
      background-color: transparent;
      color: inherit;
    }

  .styles_SearchIndicator__wY1MT .styles_chip__tScnF .styles_keyword__Aojo_ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
      max-width: 196px;
}

.styles_SearchIcon__upxjZ {
  margin-right: 0.5rem;
  color: var(--dop-color-bg-icon-level2, #aaaaaa);
}

.styles_SearchDialog__CxdYY {
  width: 1000px;
  height: calc(100vh - 120px);
}

  .styles_SearchDialog__CxdYY .styles_container__ypYb_ {
  height: 100%;
  width: 100%;
    row-gap: var(--dop-space-md, 12px);
    padding: 40px 44px;
    overflow: auto;
}

  .styles_SearchDialog__CxdYY .styles_container__ypYb_ .styles_header__IFECP {
  display: flex;
  flex-direction: column;
      row-gap: var(--dop-space-md, 12px);
      margin-bottom: var(--dop-space-2xl, 32px);
}

.styles_CategoryContainer__ii6hB {
  display: flex;
  align-items: center;

  -moz-column-gap: var(--dop-space-sm, 8px);

       column-gap: var(--dop-space-sm, 8px);
}

.styles_CateScrollButtonContainer__D1MkO {
  position: relative;

  flex-grow: 1;
  overflow: hidden;
}

.styles_CateTabContainer__XBuEM {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  overflow: hidden;

  -moz-column-gap: var(--dop-space-sm, 8px);

       column-gap: var(--dop-space-sm, 8px);
}

.styles_ScrollButton__vIo5q {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px !important;
  height: 28px !important;

  padding: 7px;

  background-color: var(--dop-color-bg-basic, #ffffff);

  border-radius: 50%;
  border: 1px solid var(--dop-color-border-basic-level3, #cfd1d4);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

  transition: opacity 0.15s ease-in-out;
}

.styles_ScrollButton__vIo5q.styles_disabled__njPWJ {
  opacity: 0;
}

.styles_ScrollButton__vIo5q.styles_active__x2UtX {
  opacity: 1;
}

.styles_ScrollButton__vIo5q.styles_left__YJDaP {
  left: 0px;
  top: 4px;
}

.styles_ScrollButton__vIo5q.styles_right__lwvBT {
  right: 0px;
  top: 4px;
}

.styles_CateTab__JuhkN {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  height: 36px;
  padding: 0 16px;
  border: 1px solid var(--dop-color-border-basic-level2, #cfd1d4);
}

.styles_CateTab__JuhkN:hover {
  cursor: pointer;
}

.styles_CateTab__JuhkN.styles_active__x2UtX {
    color: var(--dop-color-text-basic-white, #ffffff);
    background-color: var(--dop-color-bg-basic-active, #38393a);
    border: 1px solid var(--dop-color-bg-basic-active, #38393a);
  }

.styles_SearchResultContainer__mJrTA {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 1.5rem;
}

.styles_SearchResultContainer__mJrTA > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.styles_SearchResultView__tJpUd {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.75rem;
}

.styles_SearchResultView__tJpUd .styles_heading__vdsG2 {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  font-size: var(--dop-font-size-lg);
  line-height: var(--dop-font-muiti-line-height-lg);
  letter-spacing: var(--dop-font-letter-spacing-lg);
  font-weight: 600;
    height: 40px;
}

.styles_SearchResultView__tJpUd .styles_footer__gnCxF {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_SearchResultView__tJpUd .styles_footer__gnCxF .styles_moreBtn__lwrpW {
      height: 28px;
    }

.styles_SearchResultView__tJpUd .styles_footer__gnCxF .styles_moreBtn__lwrpW .styles_label__EClQt {
  font-size: var(--dop-font-size-xs);
  line-height: var(--dop-font-muiti-line-height-xs);
  letter-spacing: var(--dop-font-letter-spacing-xs);
}

.styles_SearchResultSummary__HIQ4c:not(:first-child) {
    padding-top: var(--dop-space-xl, 24px);
  }

.styles_SearchFilter__x9xg2 {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-bottom: 16px;
}

.styles_SearchFilter__Header__YD3js {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.styles_SearchFilter__Header__YD3js .styles_heading__ZMaf8 {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
  font-weight: 600;
}

.styles_SearchFilter__Header__YD3js .styles_heading__ZMaf8 .styles_title__OaX4f {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.styles_SearchFilter__Header__YD3js .styles_heading__ZMaf8 .styles_large__Tn8AZ {
  font-size: var(--dop-font-size-2xl);
  line-height: var(--dop-font-muiti-line-height-2xl);
  letter-spacing: var(--dop-font-letter-spacing-2xl);
}

.styles_SearchFilter__Header__YD3js .styles_heading__ZMaf8 .styles_medium__Wk6Mi {
  font-size: var(--dop-font-size-lg);
  line-height: var(--dop-font-muiti-line-height-lg);
  letter-spacing: var(--dop-font-letter-spacing-lg);
}

.styles_SearchFilter__Header__YD3js .styles_button__Bu_v3 {
    padding: 4px;
    border-radius: 8px;
    color: var(--dop-color-icon-basic-level3, #9b9c9e);
  }

.styles_SearchFilter__Header__YD3js .styles_button__Bu_v3 .styles_count__2in8t {
  font-size: var(--dop-font-size-sm);
  line-height: var(--dop-font-muiti-line-height-sm);
  letter-spacing: var(--dop-font-letter-spacing-sm);
  font-weight: 600;
      margin: 0 4px;
}

.styles_SearchFilter__Header__YD3js .styles_button__Bu_v3[data-open] {
    color: var(--dop-color-icon-basic-white, #ffffff);
    background-color: var(--dop-bg-basic-black, #000000);
  }

.styles_SearchFilter__Header__YD3js .styles_button__Bu_v3[data-has-filter] {
    color: var(--dop-color-text-status-information, #0d99ff);
    border: 1px solid var(--dop-color-border-status-information, #0d99ff);
    background-color: var(--dop-color-bg-status-information-level2, #e7f5ff);
  }

.styles_SearchFilter__Container__Hq9bI {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 32px 24px;
  border-radius: 12px;
  border: 1px solid var(--dop-color-border-basic-level1, #eaecef);
}

.styles_SearchFilter__Footer__jUy2p {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.styles_SearchFilter__Field__3dOdh {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  min-height: 40px;
}

.styles_SearchFilter__Field__3dOdh .label {
  flex-shrink: 0;
  align-self: flex-start;
    padding-top: 10px;
    width: 94px;
    height: 100%;
}

.styles_SearchFilter__Field__3dOdh .field {
  flex-grow: 1;
    /* padding: 0 var(--dop-space-sm, 8px);
    column-gap: 16px; */
}

.style_RadioOption__K17bV {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
}

.style_RadioInput__Vpnxj {
  flex-shrink: 0;
  width: 16px;
  height: 16px;

  border-radius: var(--Radius-L, 16px);
  border: 1px solid var(--control-border-basic, #cfd1d4);
  background: var(--control-bg-basic, #fff);
  accent-color: var(--control-border-basic-active, #38393a);
}

.style_RadioInput__Vpnxj:hover {
    border: 1px solid var(--control-border-basic-hover, #9b9c9e);
  }

.style_RadioInput__Vpnxj:checked {
    border: 1px solid var(--control-border-basic-active, #38393a);
  }

.style_RadioInput__Vpnxj:disabled {
    border: 1px solid var(--control-border-basic-disabled, #e1e3e6);
    background: var(--control-bg-basic-disabled, #eaecef);
  }

.style_RadioLabel__WL812 {
  display: flex;
  align-items: center;

  color: var(--text-basic-level1, #1c1c1c);

  /* Body/Medium-R */
  font-family: var(--font-Family, Pretendard);
  font-size: var(--font-size-Small, 14px);
  font-style: normal;
  font-weight: 400;
  letter-spacing: var(--font-letter-spacing-Small, -0.28px);
}

.style_RadioLabel__WL812.style_disabled__OzEpz {
  color: var(--text-basic-disabled, #c5c7ca);
}

.styles_DatePicker__AMyLB {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.375rem;
  padding: 0.25rem;
  border: 1px solid var(--dop-color-field-border-basic, #e1e3e6);
  color: var(--dop-color-text-basic-level1, #1c1c1c);
}

  .styles_DatePicker__AMyLB.styles_small__Svhqn {
  font-size: var(--dop-font-size-2xs) !important;
  line-height: var(--dop-font-muiti-line-height-2xs) !important;
  letter-spacing: var(--dop-font-letter-spacing-2xs) !important;
    width: 96px;
    height: 24px;
    border-radius: 4px;
}

  .styles_DatePicker__AMyLB.styles_medium__Ruxdz {
  font-size: var(--dop-font-size-xs) !important;
  line-height: var(--dop-font-muiti-line-height-xs) !important;
  letter-spacing: var(--dop-font-letter-spacing-xs) !important;
    width: 109px;
    height: 32px;
    border-radius: 6px;
}

  .styles_DatePicker__AMyLB.styles_large__qOJUW {
  font-size: var(--dop-font-size-sm) !important;
  line-height: var(--dop-font-muiti-line-height-sm) !important;
  letter-spacing: var(--dop-font-letter-spacing-sm) !important;
    width: 119px;
    height: 40px;
    border-radius: 8px;
}

  .styles_DatePicker__AMyLB.styles_active__STfzz {
    border: 1px solid var(--dop-color-field-border-basic-active, #38393a);
  }

  .styles_DatePicker__AMyLB.styles_error__JuP4B {
    border: 1px solid var(--dop-color-field-border-basic-error, #ee2e2e);
  }

  .styles_DatePicker__AMyLB:disabled,
  .styles_DatePicker__AMyLB[disabled],
  .styles_DatePicker__AMyLB.disabled {
    cursor: not-allowed;
    color: var(--dop-color-text-basic-disabled, #c5c7ca);
    border: 1px solid var(--dop-color-field-border-disabled, #e1e3e6);
    background-color: var(--dop-color-field-bg-disabled, #eaecef);
  }

.Table_Table__qfZsu table tbody tr:hover {
  background-color: #f6f7f9;
  border-radius: 10px;
}

.Table_Table__qfZsu table tbody tr:hover td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Table_Table__qfZsu table tbody tr:hover td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Table_Table__qfZsu table tr th:first-child,
.Table_Table__qfZsu table tr td:first-child {
  padding-left: 16px;
}

.Table_Table__qfZsu table tr th:last-child,
.Table_Table__qfZsu table tr td:last-child {
  padding-right: 16px;
}

.styles_Container__9GsOg {
  padding: 40px 72px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.styles_Header__K65tv {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
.styles_HeaderTitle__djcXU {
  font-size: var(--dop-font-size-xl);
  line-height: var(--dop-font-muiti-line-height-xl);
  letter-spacing: var(--dop-font-letter-spacing-xl);
  color: var(--Gray-800, #333);
  font-weight: 600;
}

.styles_Card__OPI14 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;
}

.styles_Description__NIjoe {
  color: #888;
  font-size: var(--dop-font-size-sm);
  line-height: var(--dop-font-muiti-line-height-sm);
  letter-spacing: var(--dop-font-letter-spacing-sm);
}

.styles_Contents__KNZ1d {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 12px 0;
}

.styles_Field__kTKqH {
  display: flex;
}

.styles_FieldTitle__w3wDU {
  font-size: var(--dop-font-size-sm);
  line-height: var(--dop-font-muiti-line-height-sm);
  letter-spacing: var(--dop-font-letter-spacing-sm);
  font-weight: 500;
  min-width: 200px;
  color: var(--dop-color-text-level1, #363636);
}

.styles_FieldItemRow__n_em1 {
  width: 100%;
  display: flex;
}

.styles_Select__idbd0 {
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 0px 4px;
  border-radius: 8px;
}

.multi-member-list_TableHeader__uwJEp {
  display: flex;
  justify-content: start;
  height: 48px;
  padding: 0px 8px;
}

.multi-member-list_TableCell__Ko049 {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;
}

.multi-member-list_TableCell__Ko049 > p {
  display: flex;
  align-items: center;
}

.multi-member-list_EmptyMessage__6gfvf {
  display: flex;
  width: 100%;
  padding: 60px 0;
  align-items: center;
  justify-content: center;
}

@container (max-width: 296px) {
  .styles_TableContent__kTVJl th:nth-child(1),
  .styles_TableContent__kTVJl td:nth-child(1),
  .styles_TableContent__kTVJl th:nth-child(1) p,
  .styles_TableContent__kTVJl td:nth-child(1) p,
  .styles_TableContent__kTVJl th:nth-child(3),
  .styles_TableContent__kTVJl td:nth-child(3),
  .styles_TableContent__kTVJl th:nth-child(3) p,
  .styles_TableContent__kTVJl td:nth-child(3) p {
    box-sizing: content-box;
    max-width: 44px !important;
    min-width: 44px !important;
  }
}

@container (min-width: 297px) {
  .styles_TableContent__kTVJl th:nth-child(1),
  .styles_TableContent__kTVJl td:nth-child(1),
  .styles_TableContent__kTVJl th:nth-child(1) p,
  .styles_TableContent__kTVJl td:nth-child(1) p,
  .styles_TableContent__kTVJl th:nth-child(3),
  .styles_TableContent__kTVJl td:nth-child(3),
  .styles_TableContent__kTVJl th:nth-child(3) p,
  .styles_TableContent__kTVJl td:nth-child(3) p {
    box-sizing: content-box;
    max-width: 53px !important;
    min-width: 53px !important;
  }
}

@container (min-width: 432px) {
  .styles_TableContent__kTVJl th:nth-child(1),
  .styles_TableContent__kTVJl td:nth-child(1),
  .styles_TableContent__kTVJl th:nth-child(1) p,
  .styles_TableContent__kTVJl td:nth-child(1) p,
  .styles_TableContent__kTVJl th:nth-child(3),
  .styles_TableContent__kTVJl td:nth-child(3),
  .styles_TableContent__kTVJl th:nth-child(3) p,
  .styles_TableContent__kTVJl td:nth-child(3) p {
    box-sizing: content-box;
    max-width: 156px !important;
    min-width: 156px !important;
  }
}

.styles_Today___V_N4 {
  display: flex;
  height: 22px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: var(--Space-3XSmall, 2px);
  border-radius: var(--Radius-S, 8px);
  border: 1px solid #0d99ff;
  margin-left: 4px;
  color: #0d99ff;
  font-size: 12px;
}

.styles_RecentCollectedDate__ZX6Q5 {
  font-size: var(--dop-font-size-2xs);
  line-height: var(--dop-font-muiti-line-height-2xs);
  letter-spacing: var(--dop-font-letter-spacing-2xs);
  color: var(--dop-color-text-basic-level3, #999);
  padding-bottom: 8px;
}

.styles_TableRoot__ft0Qm {
  overflow-x: auto;
  container-type: inline-size;
  contain: layout;
}

.styles_TableContent__kTVJl {
  table-layout: fixed;
  width: 100%;
}

/* .TableContent th,
.TableContent td {
  text-align: left !important;
  max-width: 103px !important;
} */

.styles_TableContent__kTVJl tbody > tr {
  cursor: pointer;
}

.styles_TableContent__kTVJl th:nth-child(1),
.styles_TableContent__kTVJl td:nth-child(1) {
  text-align: left;
  box-sizing: content-box;
}

.styles_TableContent__kTVJl th:nth-child(2),
.styles_TableContent__kTVJl td:nth-child(2) {
  box-sizing: content-box;
  width: 103px !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.styles_TableContent__kTVJl td:nth-child(3) {
  text-align: left;
}

.styles_TableContent__kTVJl td:nth-child(4) {
  width: auto;
  max-width: 100%;
  text-align: center !important;
}

.styles_TableContent__kTVJl th:nth-child(4),
.styles_TableContent__kTVJl td:nth-child(4),
.styles_TableContent__kTVJl th:nth-child(4) p,
.styles_TableContent__kTVJl td:nth-child(4) p {
  box-sizing: content-box;
  max-width: 32px !important;
  min-width: 32px !important;
}

.styles_TableContent__kTVJl td:nth-child(5) {
  text-align: right !important;
}

.styles_TableContent__kTVJl th:nth-child(5),
.styles_TableContent__kTVJl td:nth-child(5),
.styles_TableContent__kTVJl th:nth-child(5) p,
.styles_TableContent__kTVJl td:nth-child(5) p {
  box-sizing: content-box;
  max-width: 128px !important;
  min-width: 128px !important;
}

.styles_Date__r1oMm {
  font-size: 13px;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
}

.styles_Name__q5SGa {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 600;
  text-align: left;
}

.styles_BankName__zq61U {
  display: flex;
  align-items: center;
  gap: 4px;
}

.styles_Balance__l_ciu {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 700;
}

.styles_TableRoot__mjG30 {
  overflow-x: auto;
}

.styles_TableContent__6_MJB {
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
}

.styles_TableContent__6_MJB td {
  text-align: left !important;
}

.styles_TableContent__6_MJB th:nth-child(1),
.styles_TableContent__6_MJB th:nth-child(2),
.styles_TableContent__6_MJB td:nth-child(1),
.styles_TableContent__6_MJB td:nth-child(2) {
  box-sizing: content-box;
  width: 88px !important;
  max-width: 88px !important;
  min-width: 88px !important;
}

.styles_TableContent__6_MJB td:nth-child(3),
.styles_TableContent__6_MJB td:nth-child(4) {
  width: auto;
  max-width: 100%;
}

.styles_DraftDate__9MPWQ {
  display: flex;
  align-items: center;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
  font-size: 13px;
}

.styles_FormName__OcfVl {
  max-width: 88px;
  color: var(--dop-color-text-basic-level1, #363636);
  text-align: left;
}

.styles_Title__Imczy {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 600;
  text-align: left;
}

.styles_PROGRESS__EZGZV {
  font-size: var(--dop-font-size-2xs);
  line-height: var(--dop-font-muiti-line-height-2xs);
  letter-spacing: var(--dop-font-letter-spacing-2xs);
  display: inline-flex;
  padding: var(--Space-XSmall, 6px);
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #49c689;
  background: #e8f8f0;
  color: #49c689;
  font-weight: 500;
}

.styles_COMPLETE__FUj28 {
  font-size: var(--dop-font-size-2xs);
  line-height: var(--dop-font-muiti-line-height-2xs);
  letter-spacing: var(--dop-font-letter-spacing-2xs);
  display: inline-flex;
  padding: var(--Space-XSmall, 6px);
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 4px;
  background: #ccc;
  color: var(--dop-color-text-basic-white, #fff);
  font-weight: 500;
}

.styles_RecentCollectedDate__ypvPC {
  font-size: var(--dop-font-size-2xs);
  line-height: var(--dop-font-muiti-line-height-2xs);
  letter-spacing: var(--dop-font-letter-spacing-2xs);
  color: var(--dop-color-text-basic-level3, #999);
  padding-bottom: 8px;
}

.styles_TableRoot__WpYQr {
  overflow-x: auto;
}

.styles_TableContent__y9jfF {
  table-layout: fixed;
  width: 100%;
}

/* .TableContent th,
.TableContent td {
  text-align: left !important;
  max-width: 103px !important;
} */

.styles_TableContent__y9jfF th:nth-child(1),
.styles_TableContent__y9jfF td:nth-child(1),
.styles_TableContent__y9jfF th:nth-child(1) p,
.styles_TableContent__y9jfF td:nth-child(1) p {
  text-align: left;
  box-sizing: content-box;
  width: 88px !important;
  max-width: 88px !important;
  min-width: 88px !important;
}

.styles_TableContent__y9jfF th:nth-child(2),
.styles_TableContent__y9jfF td:nth-child(2) {
  text-align: left;
  box-sizing: content-box;
  width: 103px !important;
  max-width: 103px !important;
  min-width: 103px !important;
}

.styles_TableContent__y9jfF td:nth-child(3),
.styles_TableContent__y9jfF td:nth-child(4) {
  width: auto;
  max-width: 100%;
  text-align: center !important;
}

.styles_Date__oHvAr {
  font-size: 13px;
  color: var(--dop-color-text-basic-level2, #5b5b5b);
}

.styles_Item__oKlqj {
  color: var(--dop-color-text-basic-level1, #363636);
  font-weight: 600;
  text-align: left;
}

.styles_Count__SIT8Z {
  text-align: center;
}

.styles_HilightCount__U9U6n {
  color: var(--dop-color-text-status-information, #5296ff);
  font-weight: 700;
}

