.DialogTrigger {
  display: flex;
  flex-direction: column;
  padding: var(--Space-XLarge, 24px);
  border-radius: var(--Radius-M, 12px);
  border: 1px solid var(--color-border-level4, #f2f2f2);
  gap: var(--Space-Large, 16px);
  flex: 1 1 0%;

  &:hover {
    border: var(--Space-3XSmall, 1px) solid var(--color-border-level1, #363636);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.DialogContent {
  @apply flex flex-col items-center justify-center;
  max-width: 640px;
  max-height: 85vh;
  padding: 24px;
}

.DialogOverlay {
  background-color: black;
  position: fixed;
  inset: 0;
  opacity: 0.5;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
